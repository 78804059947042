import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Cookies from "js-cookie"
import { MenuItems } from "./MenuItems"
import {
  Nav,
  Logo,
  NavMenu,
  SignButton,
  Img,
  BurgerMenu,
  Drawer,
  Translate,
  FlagIcon,
  LanguageSelector,
} from "./navbarElements"

function Translation() {
  const { t, i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language || "pl")

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    Cookies.set("i18next", language, { expires: 365 })
    setCurrentLanguage(language)
  }

  useEffect(() => {
    const savedLanguage = Cookies.get("i18next")
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage)
      setCurrentLanguage(savedLanguage)
    }
  }, [i18n])

  return (
    <LanguageSelector>
      <button className="dropbtn">
        <FlagIcon
          src={
            currentLanguage === "pl"
              ? "https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg"
              : currentLanguage === "en"
              ? "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg"
              : "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg"
          }
          alt={
            currentLanguage === "pl"
              ? "PL"
              : currentLanguage === "en"
              ? "EN"
              : "CZ"
          }
        />{" "}
        {currentLanguage === "pl"
          ? "PL"
          : currentLanguage === "en"
          ? "EN"
          : "CZ"}
      </button>
      <div className="dropdown-content">
        <a onClick={() => changeLanguage("en")}>
          <FlagIcon
            src="https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg"
            alt="EN"
          />{" "}
          English
        </a>
        <a onClick={() => changeLanguage("pl")}>
          <FlagIcon
            src="https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg"
            alt="PL"
          />{" "}
          Polski
        </a>
        <a onClick={() => changeLanguage("cz")}>
          <FlagIcon
            src="https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg"
            alt="CZ"
          />{" "}
          Čeština
        </a>
      </div>
    </LanguageSelector>
  )
}

const Navbar = () => {
  const { t } = useTranslation()
  const [isScrolled, setIsScrolled] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(null)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset

      if (scrollTop > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleItemClick = (index) => {
    setActiveItem(index)
    setIsDrawerOpen(false) // Zamknij Drawer po kliknięciu linku
  }

  return (
    <Nav isScrolled={isScrolled}>
      <Logo href={"/"}>
        <Img src="logomagda.png" />
      </Logo>
      <NavMenu isScrolled={isScrolled}>
        {MenuItems.map((item, index) => (
          <li key={index}>
            <a
              href={item.url}
              className={activeItem === index ? "active" : ""}
              onClick={() => handleItemClick(index)}
            >
              {t(item.dictKey)}
            </a>
          </li>
        ))}
        <SignButton>
          <a
            href="https://magda-trans.sokar.onitsoft.com/signin"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("navbar:page.rej")}
          </a>
        </SignButton>
      </NavMenu>
      <Translation />
      <BurgerMenu
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        isOpen={isDrawerOpen}
      >
        <span />
        <span />
        <span />
      </BurgerMenu>
      <Drawer isOpen={isDrawerOpen}>
        {MenuItems.map((item, index) => (
          <a
            href={item.url}
            key={index}
            className={activeItem === index ? "active" : ""}
            onClick={() => handleItemClick(index)}
          >
            {t(item.dictKey)}
          </a>
        ))}
      </Drawer>
    </Nav>
  )
}

export default Navbar
