import React from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  FooterSection,
  Box,
  Logo,
  InputSan,
  CopyRight,
  Lista,
  InstagramIcon,
  FacebookIcon,
} from "./footerElements"
import logo_black from "../../assets/images/logomagda.png"
import { t } from "i18next"

const Footer = () => {
  return (
    <>
      <FooterSection>
        <Container>
          <Box>
            <Logo>
              <img
                style={{
                  display: "block",
                  height: "60px",
                  width: "auto",
                  position: "relative",
                  right: "-1px",
                }}
                src={logo_black}
                alt=""
              />
              <h2></h2>
              <p> {t("footer:page.about")}</p>
              <InputSan>
                <a href="/contact">{t("footer:page.callUs")}</a>
              </InputSan>
            </Logo>
          </Box>
          <Lista>
            <ul>
              <h3>{t("footer:page.information")}</h3>
              <li>NIP: 6783120981</li>
              {/* <li>REGON: 525931829</li> */}
              <li>31-553 Kraków</li>
              <li>Cystersów 13A/1 </li>
            </ul>
          </Lista>
          <Lista>
            <ul>
              <h3>{t("footer:page.contact1")}</h3>
              <li>biuro@magda-trans.pl </li>
              <li>{t("footer:page.phone")}</li>
            </ul>
          </Lista>
        </Container>
        <Container>
          <Lista>
            <ol>
              <h3>Social Media</h3>
              <li>
                <a
                  target={"_blank"}
                  href={
                    "https://www.facebook.com/profile.php?id=61557078728702"
                  }
                >
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a
                  target={"_blank"}
                  href={"https://www.instagram.com/magda_trans/"}
                >
                  <InstagramIcon />
                </a>
              </li>
            </ol>
          </Lista>
        </Container>
      </FooterSection>
      <CopyRight>
        <span>Copyright © 2024 </span>
      </CopyRight>
    </>
  )
}

export default Footer
